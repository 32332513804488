.AboutMe {
  display: flex;
  background-color: #d1f1f3;
  padding: 100px 120px;
  margin-right: auto;
  margin-left: auto;
}

.detailsLine {
  width: 200px;
}

.paragraph {
  position: relative;
  font-size: 18px;
  line-height: 1.64;
  margin: 80px 50px 20px;
}

.details {
  text-align: center;
}

.detailsList {
  text-align: center;
  line-height: 1.64;
  font-size: 18px;
}

@media (max-width: 767px) {
  .AboutMe {
    display: block;
    padding: 60px 0px;
  }
  .paragraph {
    text-align: center;
    font-size: 15px;
    margin: 20px 50px 5px;
  }
}

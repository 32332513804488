.Skills {
  background-color: #d1f1f3;
  padding: 40px 180px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  width: 100%;
  margin-left: 0px;
  margin-right: 80px;
}
.column {
  flex-direction: column;
  width: 40%;
  margin: 50px;
}
.skillNameLine {
  width: 200px;
  margin: 0px;
}

.skillsList {
  list-style: none;
  border-bottom: 2px solid lightslategray;
  line-height: 30px;
  width: 70%;
  margin-bottom: 20px;
}

.listItem {
  display: flex;
}

.Ratings {
  float: right;
}
@media (max-width: 767px) {
  .Skills {
    padding: 60px 0px;
  }
  .row {
    text-align: left;
    display: block;
    margin: 0;
  }
  .column {
    width: 100%;
    margin: 0 0 0 20px;
  }
  .Skills h2 {
    text-align: left;
  }
}

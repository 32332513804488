.NavItem {
  display: block;
  box-sizing: border-box;
  width: 135px;
  text-align: center;
  padding: 16px 10px;
  font-size: 25px;
}

.NavItem:hover,
.NavItem:active {
  background-color: lightpink;
  color: white;
  cursor: pointer;
  text-decoration: underline;
}

.Projects {
  padding: 40px 180px;
}

.ProjectPic {
  display: inline-block;
  max-width: 45%;
  margin-left: 0px;
  margin-right: 40px;
}

.projectFigure {
  margin: 10px;
  position: relative;
}

.projectImg {
  max-width: 100%;
  background-color: pink;
  display: block;
  position: relative;
}

.figCaption {
  position: absolute;
  top: 5%;
  left: 2%;
  width: 85%;
  text-align: left;
  opacity: 0;
  color: black;
}

.figCaption h3 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 5px;
}

.projectFigure:hover .projectImg {
  opacity: 0.15;
  background-color: pink;
}

.projectFigure:hover .figCaption {
  margin-left: 25px;
  opacity: 1;
}

@media (max-width: 767px) {
  .Projects {
    padding: 60px 20px;
  }
  .ProjectPic {
    margin-right: 15px;
  }
}

.ProfilePic {
  padding: 30px 140px;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ProfilePic {
    width: 70%;
    height: 250px;
    padding: 30px 0 0 50px;
  }
}

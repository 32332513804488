.vinylImg {
  width: 100%;
  position: relative;
}

@media (max-width: 767px) {
  .vinylImg {
    bottom: 200px;
    left: 0px;
    right: 0px;
    top: 0px;
  }
}

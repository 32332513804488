.NavBar {
  position: fixed;
  right: 5%;
  width: 120px;
  z-index: 999;
  bottom: 80px;
}

@media (max-width: 767px) {
  .NavBar {
    position: absolute;
    top: 46%;
    right: 55%;
    width: 50px;
    transition: transform 0.3s ease-in-out;
  }
  .NavBar.close {
    transform: translateX(-600%);
  }
  .NavBar.open {
    transform: translateY(20%);
  }
}

ul {
  padding: 0;
}

.Header {
  width: 100%;
}

.ToggleArrow {
  display: none;
}

@media (max-width: 767px) {
  .ToggleArrow {
    width: 100%;
    height: 80px;
    display: block;
    bottom: 0px;
    background-color: white;
    text-align: center;
    z-index: 6;
  }
  .Header {
    height: 812px;
  }
}

.ArrowButton {
  background-color: white;
  border: none;
}

.ArrowIcon {
  animation-name: wiggle;
  animation-duration: 1.2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: normal;
}
@keyframes wiggle {
  0% {
    padding-top: 20px;
  }
  50% {
    padding-top: 28px;
  }
  100% {
    padding-top: 20px;
  }
}

.Experiences {
  padding: 80px 180px;
}

.h2 {
  font-size: 28px;
  color: lightslategray;
  text-align: left;
}

.WorkExperiences {
  padding-bottom: 30px;
}

.experiencesRow {
  display: flex;
}

.experiencesColLeft {
  flex: 0 0 300px;
}
.experiencesColRight {
  padding-right: 100px;
}

.highlights {
  list-style: disc;
  margin: 0px;
}

@media (max-width: 767px) {
  .Experiences {
    padding: 60px 0px 0 10px;
    width: 100%;
  }
  .experiencesRow {
    display: block;
  }
  .experiencesColRight {
    padding-right: 5px;
  }
  .highlights {
    margin: 20px;
  }
}
